import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  margin: 2rem auto;
  width: 70%;
  flex-direction: column;
  gap: 1rem;
  padding-top: 70px;

  h1 {
    margin-bottom: 4rem;
  }

  h3 {
    margin-bottom: 0.5rem;
  }

  @media only screen and (min-width: 980px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

const Privacy = () => (
  <Layout seo="Vår integritetspolicy">
    <Container>
      <div>
        <h1>Vår integritetspolicy</h1>
  <p>Vi arbetar i enlighet med GDPR och följer dataskyddsförordningen, här kan du läsa om vår integritetspolicy</p>
        <h3>Vilka vi är</h3>
        <p>Vår webbplatsadress är: https://bikerepublic.se.</p>
        <h3>Cookies</h3>
        <p>
          Vi använder oss av en sessionscookie samt en cookie för Google
          Analytics, för att du ska få optimal användarupplevelse. cookien
          raderas efter att sessionen har gått ut. Google Analytics är ett
          statistik verktyg från Google som ger oss möjlighet att mäta
          besöksinteraktion på vår sajt.
        </p>
        <h3>Koppling till instagram</h3>
        <p>
          Vi importerar flödet på vår förstasida från vårt instagram konto
          @bikerpblc, vi samlar inte in någon data eller trafik via denna
          tjänst.
        </p>
      </div>
    </Container>
  </Layout>
)

export default Privacy
